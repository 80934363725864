<template>
  <div>Seja bem vindo</div>
</template>

<script>


  export default {
    name: 'Home',

created() {
  // chamar método que está no arquivo App.vue
  
},
  }
</script>
