<template>
    <v-app id="inspire">
    <v-container>
      <v-card
        elevation="16"
        width="100%"
        max-width="400px"
        class="mt-16 mx-auto">
  
        <v-toolbar dark color="#333 darken-3" height="80" elevation="0">
          <v-img src="@/assets/icon-policia.png" max-width="100" class="ml-n5"></v-img>
  
          <v-toolbar-title style="font-size: 16px; font-weight: bold;">
            POLÍCIA CIVIL DO ESTADO DO PARÁ
            <br>
            Acessar CMS
          </v-toolbar-title>
  
        </v-toolbar>
  
        <v-card-text>
          <router-view />
          <div class="mt-9 text-center">
            <span style="font-size: 13px">CMS © 2022 - DIME - Diretoria de Informática,<br>Manutenção e Estatística.</span>
          </div>
        </v-card-text>
      </v-card>
  
      
    </v-container>
    <v-footer app width="100%" dark class="text-center">
      <v-card-text class="white--text">
        CMS © {{ new Date().getFullYear() }} —
        <strong>DIME/PCPA</strong>
      </v-card-text>
    </v-footer>
  </v-app>
  </template>
  
  <script>
    
    export default {
      name: 'LoginLayout',
    }
  </script>
  