<template>
    <v-list dense>
    <v-list-item to="/home">
      <v-list-item-icon>
        <v-icon>mdi-home</v-icon>
      </v-list-item-icon>
      <v-list-item-title>Home</v-list-item-title>
    </v-list-item>
    <v-list-item
    :to="item.link" 
    v-for="(item, i) in menus" 
    :key="'menu_'+i"
    >
      <v-list-item-icon>
        <v-icon v-text="item.icone"></v-icon>
      </v-list-item-icon >
      <v-list-item-title v-text="item.nome"></v-list-item-title>
      
      </v-list-item>
      <!-- sair -->
      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Sair</v-list-item-title>
      </v-list-item>
</v-list>
</template>
<script>

import api from '@/api';

export default {
name: 'MenuComponent',
props: {
menus: { // recebe o array de menus e submenus no formato: [{nome: 'Menu 1', link: '/menu1', icone: 'home', submenus: [{nome: 'Submenu 1', link: '/submenu1', icone: 'home'}]}]
  type: Array,
  required: true
}
},
data () {
return {
  menusAtivo: false, /// para mostrar o sentido da setinha que mostra se o menu está aberto ou fechado
  idMenuPaiAberto: 0,
  idSubmenuPaiAberto: 0
}
},
methods: {
logout () { /// ação de logout que redireciona para a tela de login
  api.get('/logout')
  .finally(() => {
      // this.$store.commit("LOGOUT");
      // this.$router.push('/');
      this.$router.push('/login')
    })
},
// redirecionar para rota interna ou externa
redirecionar (link) {
  if (link.indexOf('http') === 0) {
    window.open(link, '_blank')
  } else if (link.indexOf('download/') === 0) {
    window.open(process.env.API_URL + '/' + link, '_blank')
  } else if (link) {
    this.$router.push(link)
  }
},
// exibir ou ocultar submenus
exibirSubmenu (menu) {
  this.idSubmenuPaiAberto = 0
  // fechar todos os submenus com excessão do menu selecionado
  this.menus.forEach(menu => {
    menu.exibirSubmenu = false
    menu.submenus.forEach(submenu => {
      submenu.exibirSubmenu = false
    })
  })
  /// se o menu clicado for diferente do menu que está aberto, então abre o submenu
  if (this.idMenuPaiAberto !== menu.id) {
    this.idMenuPaiAberto = menu.id
    menu.exibirSubmenu = !menu.exibirSubmenu
  } else { /// se o menu clicado for igual ao menu que está aberto, então fecha o submenu. Sem essa condição, o submenu que está aberto não fecha se clicar no seu menu pai, a não ser que outro submenu seja aberto, então automaticamente o submenu que estava aberto fecha
    this.idMenuPaiAberto = 0
    menu.exibirSubmenu = false
  }
},
exibirSUBSubmenu (menu) {
  // fechar todos os submenus com excessão do menu selecionado
  this.menus.forEach(menu => {
    menu.submenus.exibirSubmenu = false
  })
  /// se o menu clicado for diferente do menu que está aberto, então abre o submenu
  if (this.idSubmenuPaiAberto !== menu.id) {
    this.idSubmenuPaiAberto = menu.id
    menu.exibirSubmenu = !menu.exibirSubmenu
  } else { /// se o menu clicado for igual ao menu que está aberto, então fecha o submenu. Sem essa condição, o submenu que está aberto não fecha se clicar no seu menu pai, a não ser que outro submenu seja aberto, então automaticamente o submenu que estava aberto fecha
    this.idSubmenuPaiAberto = 0
    menu.exibirSubmenu = false
  }
}
}
}
</script>
