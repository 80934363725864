<template>
    <v-app id="inspire">
      <v-app-bar app dark>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>{{ sistema }}</v-toolbar-title>
      </v-app-bar>
  
      <v-navigation-drawer v-model="drawer" dark app width="256">
        <v-list>
          <v-list-item>
            <v-list-item-avatar style="margin-left: 5em;">
              <v-img src="@/assets/icon-policia.png"></v-img>
            </v-list-item-avatar>
          </v-list-item>
          <v-list-item v-if="$store.state.usuario.nome">
            {{ $store.state.usuario.nome }}
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <MenuComponent :menus="items" />
      </v-navigation-drawer>
  
  
      <v-main style="margin: 5px;">
        <router-view />
      </v-main>
  
      <v-footer app width="100%" dark class="text-center">
        <v-card-text class="white--text">
          {{ sistema }} © {{ new Date().getFullYear() }} —
          <strong>DIME/PCPA</strong>
        </v-card-text>
      </v-footer>
    </v-app>
  
  </template>
  
  
  <script>
  
  import api from '@/api';
  import MenuComponent from '../components/MenuComponent.vue';
  
  export default {
    name: 'DashboardLayout',
    components: {
      MenuComponent
    },
    //criar vue guard para validar se o usuário está logado
    beforeRouteEnter (to, from, next) {
      api.get('/validarUsuarioLogado')
        .then(response => {
          if (response.data.results === true) {
            next(vm => {
              vm.user = response.data.user
            })
          } else {
            next('/login')
          }
        })
    },
    data: () => ({
      sistema: process.env.VUE_APP_SISTEMA,
      selectedItem: 0,
      drawer: null,
      items:[],
      icones: [
        'mdi-home',
        'mdi-email',
        'mdi-calendar',
        'mdi-delete'
      ]
    }),
    created() {
        
    this.carregarMenu ()
      
    //   if (this.$router.currentRoute.path !== '/') {
  
    //     this.$store.commit('EXIBIR_MENU', true);
  
    //     //verifica se o usuário perdeu a sessão
    //     if (!this.$store.getters.IS_LOGADO) {
    //       this.$store.commit("LOGOUT");
    //       this.$router.push('/');
    //     } else {
    //       this.$store.commit('EXIBIR_MENU', true);
    //     }
  
    //   }
    //   else {
    //     this.$store.commit('EXIBIR_MENU', false);
    //   }
    },
    methods: {
      carregarMenu () {
        api.get('/get-menu')
          .then(response => {
            this.items = response.data
          }).catch(error => {
            // this.$router.push('/logout');
            console.log(error)
          })
      }
    }
  };
  </script>
  