<template>
  <v-data-table
    :items="this.rows"
    :headers="columns"
    :search="filter"
    single-select
    show-select
    v-model="selectedRows"
    item-key="id"
>
<template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title></v-toolbar-title>
        <v-text-field
          v-model="filter"
          dense
          placeholder="Buscar..."
          append-icon="mdi-magnify"
        ></v-text-field>
        <v-spacer></v-spacer>
        
          <v-btn
            v-for="(btn, index) in btnAcao"
            :key="index"
            :small="btn.size === 'sm'"
            :dense="btn.dense"
            :rounded="btn.round"
            :color="btn.color || 'secondary'"
            :disabled="btn.disable === false ? false : selectedRows.length === 0"
            @click="btn.acao"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">{{ btn.icone }}</v-icon>
              </template>
              <span>{{ btn.tooltip || 'Adicionar' }}</span>
            </v-tooltip>
          </v-btn>
        

      </v-toolbar>
    </template>
    <template v-slot:item.status="{ item }">
      <v-switch
        v-model="item.status"
        color="success"
        @click="atualizaStatus(item)"
      ></v-switch>
    </template>
  </v-data-table>

</template>

<script>
export default {
  name: 'TableDefaultComponent',
  props: {
    rows: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    btnAcao: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  emits: ['row-selected', 'atualiza-status'],
  data () {
    return {
      selectedRows: '',
      pageSize: 50,
      nextPage: 1,
      lastPage: Math.ceil(this.rows.length / this.pageSize),
      pagination: {
        rowsPerPage: 10,
        page: 1
      },
      filter: '',
      carregando: true
    }
  },
  watch: {
    rows () {
      this.carregando = false
    },
    selectedRows () {
      // console.log(this.selectedRows[0].id)
      this.$emit('row-selected', this.selectedRows[0].id)
    }
  },
  methods: {
    atualizaStatus (row) {
      this.$emit('atualiza-status', row)
    },
  }
}
</script>

<style scoped>
.my-sticky-dynamic {
  height: 620px;
}

.v-data-table__wrapper {
  max-height: 100%;
}

.v-data-table-header th {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}
</style>